import {
    REQUEST_FRONTEND,
    RECEIVE_FRONTEND,
    ERROR_FRONTEND,
    TOGGLE_DEV_FRONTEND
} from '../types'
import {initialFrontendFetchState} from '../../constants/defaultValues'
import _cloneDeep from 'lodash/cloneDeep'
import isEmpty from "lodash/isEmpty"

export default (state = initialFrontendFetchState, action = {}) => {
    const newState = _cloneDeep(state)
    switch(action.type) {
        case REQUEST_FRONTEND:
            if(action.subType){
                newState[action.dataType][action.subType] = {
                    data: {},
                    isFetching: true
                }
            }else{
                newState[action.dataType].isFetching = true
            }
            return newState
        case RECEIVE_FRONTEND:
            if(action.subType){
                newState[action.dataType][action.subType] = {
                    ...newState[action.dataType][action.subType],
                    isFetching: false,
                    data: action.payload,
                    message: ''
                }
            }else{
                newState[action.dataType] = {
                    ...newState[action.dataType],
                    isFetching: false,
                    data: action.payload,
                    message: ''
                }
            }
            return newState
        case TOGGLE_DEV_FRONTEND:
            const ns = _cloneDeep(state.landing)
            if(isEmpty(state.landing.data.originalTexts)){
                ns.data.originalTexts = state.landing.data.texts
                ns.data.texts = {}
                Object.keys(state.landing.data.texts).map(key => {
                    ns.data.texts[key] = key
                })
            }else{
                ns.data.originalTexts = {}
                ns.data.texts = state.landing.data.originalTexts
            }
            return {
                ...state,
                landing: ns
            }
        case ERROR_FRONTEND:
            if(action.subType){
                newState[action.dataType][action.subType] = {
                    ...newState[action.dataType][action.subType],
                    isFetching: false,
                    data: {},
                    message: 'unexpected_error'
                }
            }else{
                newState[action.dataType] = {
                    ...newState[action.dataType],
                    isFetching: false,
                    data: [],
                    message: 'unexpected_error'
                }
            }
            return newState
        default:
            return state
    }
}