module.exports = {
    "name": "Družstvo pro výstavbu a správu <span>garáží Chomutov</span>",
    "about-button": "Aktuální informace",
    "contact": "Kontaktní údaje",
    "contact-info": "V případě dotazů nás kontaktujte emailem, telefonicky nebo využijte kontaktní formulář.",
    "references": "Říkáte o nás",
    "read-more": "Přečíst",
    "latest-news": "Nejnovější aktuality",
    "pages": "Informace",
    "directors": "Představenstvo",
    "commisson": "Kontrolní komise",
    "gallery": "Fotogalerie",
    "opening-hours": "Pracovní doba",
    "show-on-map": "Zobrazit na mapě",
    "nav.about": "Úvod",
    "news-heading": "Aktuality",
    "news-subheading": "Co je u nás nového",
    "contact-heading": "Napište nám",
    "contact-subheading": "Nevíte si rady?",
    "useful-info-heading": "Užitečné informace",
    "useful-info-subheading": "Může se hodit",
    "all-news": "Všechny aktuality",
    "cookies.heading": "Nastavení Cookies",
    "cookies.text": "Na našich webových stránkách používáme soubory Cookies a další technologie. Některé z nich jsou nezbytně nutné, zatímco jiné nám pomáhají zlepšovat naše online služby. Soubory Cookies, které nejsou nutné, můžete přijmout nebo odmítnout kliknutím na „Individuální nastavení“ a následným výběrem nabízených možností. Toto nastavení můžete kdykoli znovu vyvolat kliknutím na symbol souborů Cookies a upravit výběr souborů Cookies.",
    "cookies.necessary-heading": "Nezbytně nutné",
    "cookies.necessary-text": "Tyto Cookies jsou nezbytné pro správné zobrazení a funkce webových stránek a není možné je vypnout.",
    "cookies.analytics-heading": "Analytické",
    "cookies.analytics-text": "Tyto Cookies nám umožňují anonymně analyzovat chování návštěvníka našich webových stránek pomocí sbírání a porovnávání informací.",
    "cookies.accept-all": "Přijmout vše",
    "cookies.individual-settings": "Individuální nastavení",
    "cookies.save-selection": "Uložit nastavení",
    "cookies.close-individual-settings": "Zavřít",
    "cookies.protection-of-personal-data": "Zásady ochrany osobních údajů",
    "input.your-name": "Vaše jméno",
    "input.email": "Váš email",
    "input.message": "Zpráva",
    "general.button-send": "Odeslat",
    "general.sending": "Odesílám",
    "contact-message-sent": "Zpráva byla úspěšně odeslána.",
    "contact-ok": "OK",
    "contact-message-failed": "Zprávu se nepodařilo odeslat.",
    "contact-try-again": "Zkusit znovu",
    "ico": "Ičo",
    "bank": "Číslo účtu",
    "dat": "Datová schránka",
    "contact-persons": "Kontaktní informace",
    "validation.item_required": "Tato položka je povinná"
}
