/*
LocalStorage keys
*/
export const storage = {
    authentication: 'Authorization_' + btoa(window.location.host),
    sidebar: '__sidebar',
    cookie_settings: '__cs'
}

export const defaultLocale = "cs-CZ"

export const defaultSidebar = ''

//cookie disclaimer
export const defaultCookieSettings = {
    is_default: true,
    cookies: {
        necessary: true,
        analytics: false
    }
}

// Toaster
export const defaultToaster = {
    toasterType: "OK",
    title: '',
    message: '',
    counter: 0
}

//fetch state
export const initialFetchState = {
    data: {},
    isFetching: false,
    shouldReload: false,
    errors: {},
    message: ''
}

//fetch sequence state
export const initialSequenceFetchState = {
    data: [],
    isFetching: false,
    errors: {},
    message: ''
}

export const initialFrontendFetchState = {
    'landing': {
        data: {},
        isFetching: false
    },
    'news': {},
    'newsList': {
        data: [],
        isFetching: false
    },
    'team': {
        data: [],
        isFetching: false
    },
    'pages': {},
}

//enum fetch state
export const initialEnumFetchState = {
    'filter-boolean': {
        data: [
            {value: 1, label: 'yes'},
            {value: 0, label: 'no'},
            {value: null, label: 'any'},
        ],
        isFetching: false,
        shouldReload: false
    },
    'pdf-actions': {
        data: {
            download: {value: 'download', label: 'download'},
            show: {value: 'show', label: 'show'},
        },
        isFetching: false,
        shouldReload: false
    },
    'team-types': {
        data: {
            0: {value: '0', label: 'director'},
            1: {value: '1', label: 'commision'},
        },
        isFetching: false,
        shouldReload: false
    }
}

//enum fetch Item state
export const initialEnumItemFetchState = {
    data: [],
    isFetching: false,
    shouldReload: false
}

//user state
export const initialUserFetchState = {
    isAuthenticated: false,
    isFetching: false,
    user: {},
}

