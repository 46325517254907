/*TOASTER*/
export const SET_TOASTER = "SET_TOASTER"

/*COOKIE*/
export const SET_COOKIE_SETTINGS = "SET_COOKIE_SETTINGS"

/*SIDEBAR*/
export const CHANGE_SIDEBAR = "CHANGE_SIDEBAR"

/* FILTERS */
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const TOGGLE_FILTER = "TOGGLE_FILTER"

/* AUTH */
export const REQUEST_CURRENT_USER = "REQUEST_CURRENT_USER"
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER"
export const ERROR_CURRENT_USER = "ERROR_CURRENT_USER"

/* INFO */
export const REQUEST_INFO = "REQUEST_INFO"
export const RECEIVE_INFO = "RECEIVE_INFO"
export const ERROR_INFO = "ERROR_INFO"

/* FRONTEND */
export const REQUEST_FRONTEND = "REQUEST_FRONTEND"
export const RECEIVE_FRONTEND = "RECEIVE_FRONTEND"
export const ERROR_FRONTEND = "ERROR_FRONTEND"
export const TOGGLE_DEV_FRONTEND = "TOGGLE_DEV_FRONTEND"

/* STRINGS */
export const REQUEST_STRINGS = "REQUEST_STRINGS"
export const RECEIVE_STRINGS = "RECEIVE_STRINGS"
export const UPDATE_STRINGS = "UPDATE_STRINGS"
export const ERROR_STRINGS = "ERROR_STRINGS"
export const TOGGLE_DEV_STRINGS = "TOGGLE_DEV_STRINGS"

/* ADMIN STRINGS */
export const REQUEST_ADMIN_STRINGS = "REQUEST_ADMIN_STRINGS"
export const RECEIVE_ADMIN_STRINGS = "RECEIVE_ADMIN_STRINGS"
export const UPDATE_ADMIN_STRINGS = "UPDATE_ADMIN_STRINGS"
export const ERROR_ADMIN_STRINGS = "ERROR_ADMIN_ROLES"

/* ADMINS */
export const REQUEST_ADMINISTRATORS = "REQUEST_ADMINISTRATORS"
export const RECEIVE_ADMINISTRATORS = "RECEIVE_ADMINISTRATORS"
export const UPDATE_ADMINISTRATORS = "UPDATE_ADMINISTRATORS"
export const ERROR_ADMINISTRATORS = "ERROR_ADMINISTRATORS"

/* ROLES */
export const REQUEST_ROLES = "REQUEST_ROLES"
export const RECEIVE_ROLES = "RECEIVE_ROLES"
export const UPDATE_ROLES = "UPDATE_ROLES"
export const ERROR_ROLES = "ERROR_ROLES"

/* PERMISSIONS */
export const REQUEST_PERMISSIONS = "REQUEST_PERMISSIONS"
export const RECEIVE_PERMISSIONS = "RECEIVE_PERMISSIONS"
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS"
export const ERROR_PERMISSIONS = "ERROR_PERMISSIONS"

/* FAQ */
export const REQUEST_NEWS = "REQUEST_NEWS"
export const RECEIVE_NEWS = "RECEIVE_NEWS"
export const UPDATE_NEWS = "UPDATE_NEWS"
export const ERROR_NEWS = "ERROR_NEWS"

/* PAGES */
export const REQUEST_PAGES = "REQUEST_PAGES"
export const RECEIVE_PAGES = "RECEIVE_PAGES"
export const UPDATE_PAGES = "UPDATE_PAGES"
export const ERROR_PAGES = "ERROR_PAGES"

/* PDF-DOCUMENTS */
export const REQUEST_PDF_DOCUMENTS = "REQUEST_PDF_DOCUMENTS"
export const RECEIVE_PDF_DOCUMENTS = "RECEIVE_PDF_DOCUMENTS"
export const UPDATE_PDF_DOCUMENTS = "UPDATE_PDF_DOCUMENTS"
export const ERROR_PDF_DOCUMENTS = "ERROR_PDF_DOCUMENTS"

/* NOTIFICATIONS */
export const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS"
export const RECEIVE_NOTIFICATIONS = "RECEIVE_NOTIFICATIONS"
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"
export const ERROR_NOTIFICATIONS = "ERROR_NOTIFICATIONS"

/* TEAM */
export const REQUEST_TEAM = "REQUEST_TEAM"
export const RECEIVE_TEAM = "RECEIVE_TEAM"
export const UPDATE_TEAM = "UPDATE_TEAM"
export const ERROR_TEAM = "ERROR_TEAM"

/* USEFULINFO */
export const REQUEST_USEFUL_INFO = "REQUEST_USEFUL_INFO"
export const RECEIVE_USEFUL_INFO = "RECEIVE_USEFUL_INFO"
export const UPDATE_USEFUL_INFO = "UPDATE_USEFUL_INFO"
export const ERROR_USEFUL_INFO = "ERROR_USEFUL_INFO"


/* GALLERY */
export const REQUEST_GALLERY = "REQUEST_GALLERY"
export const RECEIVE_GALLERY = "RECEIVE_GALLERY"
export const UPDATE_GALLERY = "UPDATE_GALLERY"
export const ERROR_GALLERY = "ERROR_GALLERY"

/* ENUMS */
export const REQUEST_ENUMS = "REQUEST_ENUMS"
export const RECEIVE_ENUMS = "RECEIVE_ENUMS"
export const RELOAD_ENUMS = "RELOAD_ENUMS"
export const ERROR_ENUMS = "ERROR_ENUMS"

/* ITEM */
export const REQUEST_ITEM = "REQUEST_ITEM"
export const RECEIVE_ITEM = "RECEIVE_ITEM"
export const UPDATE_ITEM = "UPDATE_ITEM"
export const ERROR_ITEM = "ERROR_ITEM"

/* SEQUENCE */
export const REQUEST_SEQUENCE = "REQUEST_SEQUENCE"
export const RECEIVE_SEQUENCE = "RECEIVE_SEQUENCE"
export const ERROR_SEQUENCE = "ERROR_SEQUENCE"